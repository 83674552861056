import Vue from 'vue'
import VueRouter from 'vue-router'
//Middleware
import Middlewares from '../Middlewares/Index'

Vue.use(VueRouter)
const routes = [
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/Dashboard.vue')
    },
    {
        path: '/truck',
        name: 'Truck',
        component: () => import('@/views/Truck/truck.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/factory',
        name: 'Factory',
        component: () => import('@/views/Factory/factory.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/user/factory',
        name: 'UserFactory',
        component: () => import('@/views/Users/Factory/factory.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    {
        path: '/sorting-center',
        name: 'SortingCenter',
        component: () => import('@/views/SortingCenter/sorting_center.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    {
        path: '/sorting-center-user',
        name: 'SortingCenterUser',
        component: () => import('@/views/Users/SortingCenter/sorting_center_user.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    // Route plan
    {
        path: '/route-plan/plan',
        name: 'Plan',
        component: () => import('@/views/RoutePlan/Plans/plan.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/route-plan/map-view',
        name: 'PlanMapView',
        component: () => import('@/views/RoutePlan/Plans/route_plan_map.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    {
        path: '/route-plan/create',
        name: 'CreatePlan',
        component: () => import('@/views/RoutePlan/Plans/create_plan.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    {
        path: '/route-plan/plan/selected',
        name: 'SelectedCreatePlan',
        component: () => import('@/views/RoutePlan/Plans/selected_create_plan.vue'),
        props: true,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/route-plan/plan/edit/:id',
        name: 'EditPlan',
        component: () => import('@/views/RoutePlan/Plans/edit_plan.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/route-plan/plan/:id',
        name: 'ViewPlan',
        component: () => import('@/views/RoutePlan/Plans/view_plan.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    {
        path: '/route-plan/calendar',
        name: 'Calendar',
        component: () => import('@/views/RoutePlan/Calendar/calendar.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/route-plan/calendar/:id',
        name: 'CalendarDetail',
        component: () => import('@/views/RoutePlan/Calendar/calendar_detail.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    {
        path: '/route-plan/calendar/:id/:detail_id',
        name: 'CalendarDetailCustomer',
        component: () => import('@/views/RoutePlan/Calendar/calendar_detail_customer.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
//Branches
    {
        path: '/branch',
        name: 'Branch',
        component: () => import('@/views/Users/Branch/branch.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/user/branch',
        name: 'Branch_User',
        component: () => import('@/views/Users/Branch/branch_user.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/user/delivery',
        name: 'Delivery_User',
        component: () => import('@/views/Users/Delivery/delivery_user.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/customer',
        name: 'Customer',
        component: () => import('@/views/Users/Customer/tabindex.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },

//Franchise
    {
        path: '/branch/invoice',
        name: 'ReportBranchInvoice',
        component: () => import('@/views/Report/branch/invoice.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/branch/invoice/:id',
        name: 'ReportBranchInvoiceDetail',
        component: () => import('@/views/Report/branch/invoiceBranchDetail.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    // Create customer
    {
        path: '/customer/create',
        name: 'CustomerCreate',
        component: () => import('@/views/Users/Customer/createCustomer.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/customer/update/:id',
        name: 'CustomerUpdate',
        component: () => import('@/views/Users/Customer/editCustomer.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    {
        path: '/village',
        name: 'Village',
        component: () => import('@/views/Village/village.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/department',
        name: 'department',
        component: () => import('@/views/Department/department.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },

//Water Route
    {
        path: '/water-bottle',
        name: 'Water',
        component: () => import('@/views/Bottle/alltab.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/',
        name: 'Login',
        component: () => import('@/views/Auth/Login.vue'),
        meta: {
            middleware: [Middlewares.guest],
            hiddens: true,
        }
    },
    {
        path: '/report-bottle',
        name: 'Report-Bottle',
        component: () => import('@/views/Report/bottle/tabindex.vue')
    },
    {
        path: '/report-empty-bottle',
        name: 'Report-Empty-Bottle',
        component: () => import('@/views/Report/emptyBottle/tabindex.vue')
    },
// Finance
    {
        path: '/finance/report-invoice',
        name: 'Finance',
        component: () => import('@/views/Finance/index.vue')
    },

    // Invoice detail
    {
        path: '/report-invoice/:id',
        name: 'InvoiceDetail',
        component: () => import('@/views/Finance/invoiceDetail.vue')
    },

//Package
    {
        path: '/package',
        name: 'Package',
        component: () => import('@/views/Package/Package.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
//Package
    {
        path: '/subscription',
        name: 'Subscription',
        component: () => import('@/views/Package/Subscription.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/customer/register',
        name: 'Customer-Register',
        component: () => import('@/views/CustomerRegister.vue'),
        meta: {
            hiddens: true,
        }
    },
// Return Money to Customer
    {
        path: '/return-money',
        name: 'Return-Money-Customer',
        component: () => import('@/views/Report/customer/tabindex.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
// Report Customer
// {
//   path: '/report-customer',
//   name: 'Report-Customer',
//   component: () => import('@/views/Report/customer/customer_report_sale.vue'),
//   meta:{
//     middleware: [Middlewares.auth],
//   }
// },
// {
//   path: '/report-customer-none-active',
//   name: 'None-Active-Customer',
//   component: () => import('@/views/Report/customer/customer_report_no_sale.vue'),
//   meta:{
//     middleware: [Middlewares.auth],
//   }
// },

// {
//   path: '/report-customer-close',
//   name: 'Customer-Close',
//   component: () => import('@/views/Report/customer/customer_close.vue'),
//   meta:{
//     middleware: [Middlewares.auth],
//   }
// },
    {
        path: '/report-customer/:id',
        name: 'Report-Customer-Detail',
        component: () => import('@/views/Report/customer/customer_report_sale_detail.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
//Lost Bottle
    {
        path: '/bottle-broken',
        name: 'Bottle-Broken',
        component: () => import('@/views/BottleBroken/tabindex.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    //issue bottle
    {
        path: '/issue-bottle',
        name: 'Issue-Bottle',
        component: () => import('@/views/Report/bottle/report_issue_bottle.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    //tracking bottle
    {
        path: '/tracking-bottle',
        name: 'tracking-bottle',
        component: () => import('@/views/TrackingBottle.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
//Warehouse
    {
        path: '/warehouse',
        name: 'Warehouse',
        component: () => import('@/views/warehouse/products/index.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/product/category',
        name: 'category',
        component: () => import('@/views/warehouse/products/productCategory.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list/supplier',
        name: 'supplier',
        component: () => import('@/views/warehouse/products/supplier.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/product/purchase',
        name: 'purchase',
        component: () => import('@/views/warehouse/products/purchaseProduct.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/product/exportwarehouse',
        name: 'Export-Warehouse',
        component: () => import('@/views/warehouse/products/exportWarehouse.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/product/returntowarehouse',
        name: 'Return-Warehouse',
        component: () => import('@/views/warehouse/products/returnWarehouseProduct.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
//Report
    {
        path: '/warehouse/report',
        name: 'Warehouse-Report',
        component: () => import('@/views/warehouse/report/index.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    //Platform
    {
        path: '/news',
        name: 'News',
        component: () => import('@/views/Platform/News/News.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    //Create News
    {
        path: '/news/create',
        name: 'CreateNews',
        component: () => import('@/views/Platform/News/CreateNews.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/news/update/:id',
        name: 'UpdateNews',
        component: () => import('@/views/Platform/News/UpdateNews.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    //Banner
    {
        path: '/banner',
        name: 'Banner',
        component: () => import('@/views/Platform/Banner/Banner.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/banner/create',
        name: 'CreateBanner',
        component: () => import('@/views/Platform/Banner/CreateBanner'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/banner/update/:id',
        name: 'UpdateBanner',
        component: () => import('@/views/Platform/Banner/UpdateBanner'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    //Promotion
    {
        path: '/promotion',
        name: 'Promotion',
        component: () => import('@/views/Platform/Promotions/Promotion'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/promotion/create',
        name: 'CreatePromotion',
        component: () => import('@/views/Platform/Promotions/CreatePromotion'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/promotion/update/:id',
        name: 'UpdatePromotion',
        component: () => import('@/views/Platform/Promotions/UpdatePromotion'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    //User Promotion
    {
        path: '/customer/promotion',
        name: 'CustomerPromotion',
        component: () => import('@/views/Platform/CustomerPromotion/CustomerPromotion'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/customer/promotion/create',
        name: 'CreateCustomerPromotion',
        component: () => import('@/views/Platform/CustomerPromotion/CreateCustomerPromotion'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/customer/use/promotion',
        name: 'CustomerUsePromotion',
        component: () => import('@/views/Platform/CustomerPromotion/CustomerUsePromotion'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    //News Promotion
    {
        path: '/news/promotion',
        name: 'NewsPromotion',
        component: () => import('@/views/Platform/NewsPromotion/NewsPromotion'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/news/promotion/create',
        name: 'CreateNewsPromotion',
        component: () => import('@/views/Platform/NewsPromotion/CreateNewsPromotion'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/news/promotion/:id',
        name: 'UpdateNewsPromotion',
        component: () => import('@/views/Platform/NewsPromotion/UpdateNewsPromotion'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    //Notification
    {
        path: '/notifications',
        name: 'Notification',
        component: () => import('@/views/Platform/Notification/Notification'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/notification/create',
        name: 'CreateNotification',
        component: () => import('@/views/Platform/Notification/CreateNotification'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/notification/update/:id',
        name: 'UpdateNotification',
        component: () => import('@/views/Platform/Notification/UpdateNotification'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/customer/request',
        name: 'CustomerRequest',
        component: () => import('@/views/Platform/CustomerRequest/CustomerRequest'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/customer/order-water',
        name: 'CustomerRequestOrderWater',
        component: () => import('@/views/Platform/CustomerOrderWater/CustomerRequestOrderWater'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    ///ສັ່ງນ້ຳດື່ມເພີ່ມ
    {
        path: '/customer/add-order-water',
        name: 'CustomerOrderWater',
        component: () => import('@/views/Platform/CustomerOrderWater/CustomerOrderWater'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    {
        path: '/customer/request/:id',
        name: 'CustomerRequestDetail',
        component: () => import('@/views/Platform/CustomerRequest/CustomerRequestDetail'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    //Order
    {
        path: '/customer/order',
        name: 'CustomerOrder',
        component: () => import('@/views/Platform/Order/CustomerOrder'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    //Reject
    {
        path: '/reject',
        name: 'RejectReason',
        component: () => import('@/views/Setting/RejectReason'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    //Product Type
    {
        path: '/product/type',
        name: 'ProductType',
        component: () => import('@/views/Setting/ProductType'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },


    // Branch User

    {
        path: '/report-branch-sale',
        name: 'SaleReport',
        component: () => import('@/views/BranchLayout/SaleReport'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/branch/route-plan',
        name: 'RoutePlan',
        component: () => import('@/views/BranchLayout/RoutePlan/calendar'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/branch/route-plan/calendar/:id',
        name: 'BranchCalendarDetail',
        component: () => import('@/views/BranchLayout/RoutePlan/calendar_detail.vue'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    {
        path: '/branch-report-bottle',
        name: 'BottleReport',
        component: () => import('@/views/BranchLayout/BottleReport'),
        meta: {
            middleware: [Middlewares.auth],
        }
    },


    // {
    //     path: '/default',
    //     name: 'BranchIndex',
    //     component: () => import('@/views/BranchLayout/index'),
    //     meta: {
    //         middleware: [Middlewares.auth],
    //     },
    //     children: [
    //         {
    //             path: '/report-branch',
    //             name: 'SaleReport',
    //             component: () => import('@/views/BranchLayout/SaleReport'),
    //             // meta: {
    //             //     middleware: [Middlewares.guest],
    //             // }
    //         },
    //         {
    //             path: '/report-bottle',
    //             name: 'BottleReport',
    //             component: () => import('@/views/BranchLayout/BottleReport'),
    //             // meta: {
    //             //     middleware: [Middlewares.guest],
    //             // }
    //         },
    //
    //         {
    //             path: '/route-plan',
    //             name: 'RoutePlan',
    //             component: () => import('@/views/BranchLayout/index'),
    //             // meta: {
    //             //     middleware: [Middlewares.guest],
    //             // }
    //         },
    //
    //     ]
    // },

]

const router = new VueRouter({
    mode: 'history',
//  base: process.env.BASE_URL,
    routes
})


function nextCheck(context, middleware, index) {
    const nextMiddleware = middleware[index];

    if (!nextMiddleware) return context.next();

    return (...parameters) => {
        context.next(...parameters);
        const nextMidd = nextCheck(context, middleware, index + 1);

        nextMiddleware({...context, nextMidd});
    }
}

router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];
        const ctx = {
            from,
            next,
            router,
            to
        }

        const nextMiddleware = nextCheck(ctx, middleware, 1);
        return middleware[0]({...ctx, nextMiddleware});

    }
    return next();
});

export default router
